module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.10.0_gatsby@5.10.0_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.10.0_gatsby@5.10.0_graphql@16.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/mascot-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4fd3e2c4debd750bfbba159dd2bc89d6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.10.0_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.1.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
